import moment from 'moment';
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import { MODALIDAD_FACTURACION } from '@/utils/globalConventions';
import validarParametroFacturacionSiat from '@/store/modules/facturacion/utils/siatValidations';

export default async function callEditApi({ rootState, commit, dispatch }, id) {
  try {
    const RESPONSE = await axios.get(`/clinic/invoicing/${id}/edit`);
    const VENTA = RESPONSE.data.data.venta;
    const { tratamiento } = RESPONSE.data.data.venta;
    const { ajustes } = RESPONSE.data.data;
    const CATEGORIA_SERVICIOS = RESPONSE.data.data.categoria_servicios;
    const { detalles } = RESPONSE.data.data;
    const FORMA_PAGO = RESPONSE.data.data.forma_pago;
    const TESORERIAS_SELECTED = RESPONSE.data.data.forma_pago_tesorias;
    const FORMAS_DE_PAGO = RESPONSE.data.data.tipo_forma_pagos;
    const MEDIO_PAGO = RESPONSE.data.data.medio_pago;
    const MEDIOS_DE_PAGO = RESPONSE.data.data.tipo_medio_pagos;
    const ACTIVIDAD_ECONOMICA_ID = RESPONSE.data.data.venta.actividad_economica_id;
    /* const { factura } = RESPONSE.data.data.venta; */
    const { aseguradoras } = RESPONSE.data.data;
    const SEGURO_MEDICO = RESPONSE.data.data.venta.seguro_medico;
    const ASEGURADORA_ID = RESPONSE.data.data.venta.aseguradora_id;
    const ASEGURADORA_DOBLE_ID = RESPONSE.data.data.venta.aseguradora_doble_id;
    const FACTURA_NIT = RESPONSE.data.data.venta.factura_nit;
    const FACTURA_RAZON_SOCIAL = RESPONSE.data.data.venta.factura_nombre;
    const FACTURA_TERCERO = (RESPONSE.data.data.venta.factura_tercero === 1);
    const { fecha } = RESPONSE.data.data.venta;
    const FECHA_FACTURA = new Date(moment(fecha));
    const { glosa } = RESPONSE.data.data.venta;
    const GLOSA_TIPO = (RESPONSE.data.data.venta.glosa_tipo === 1);
    const { medicos } = RESPONSE.data.data;
    const MEDICO_SELECTED_ID = RESPONSE.data.data.venta.medico_id;
    const { monedas } = RESPONSE.data.data;
    const MONEDA_SELECTED_ID = RESPONSE.data.data.venta.moneda_id;
    const { tc } = RESPONSE.data.data.venta;
    const { numero } = RESPONSE.data.data.venta;
    const NUMERO_FACTURA = RESPONSE.data.data.venta.numero_factura;
    const { pacientes } = RESPONSE.data.data;
    const PACIENTE_SELECTED_ID = RESPONSE.data.data.venta.paciente_id;
    const { sucursales } = RESPONSE.data.data;
    const SUCURSAL_ID = RESPONSE.data.data.venta.sucursal_id;
    const TIPO_FACTURA = RESPONSE.data.data.venta.tipo_factura ?? '';
    const TOTAL_SEGURO = RESPONSE.data.data.venta.total_seguro;
    const DESCUENTO = RESPONSE.data.data.venta.descuento_monto;
    const total = parseFloat(RESPONSE.data.data.venta.total);
    const totalVenta = parseFloat(total) + parseFloat(DESCUENTO);
    let SUB_TOTAL = totalVenta;
    if (!RESPONSE.data.data.venta.aseguradora_doble_id) {
      SUB_TOTAL = parseFloat(totalVenta) + parseFloat(TOTAL_SEGURO ?? 0);
    }
    const VALOR_CERO = RESPONSE.data.data.venta.valor_cero;
    const TIPO_DOCUMENTOS = RESPONSE.data.data.tipo_documentos;
    const modalidadesFacturacion = RESPONSE.data.data.modalidades_configuracion;
    const SERVICIOS_COMPLEMENTARIOS = RESPONSE.data.data.nombre_parametro_servicio_complementario;
    const medicosTratantes = RESPONSE.data.data.tratantes;
    const MEDICO_DERIVADOR = RESPONSE.data.data.derivador;
    const NUMERO_RESERVA = RESPONSE.data.data.venta.numero_reserva;
    const RESERVA_ID = RESPONSE.data.data.venta.reserva_id;
    const NUMERO_TRATAMIENTO = RESPONSE.data.data.venta.numero_tratamiento;

    const LISTA_CONTINGENCIAS = validarParametroFacturacionSiat(
      RESPONSE.data.data.tipo_factura_contingencias,
      false,
      rootState.main.extrafacturaElectronica,
    );
    const LISTA_TIPO_EMISIONS = validarParametroFacturacionSiat(
      RESPONSE.data.data.tipo_emisions,
      true,
      rootState.main.extrafacturaElectronica,
    );
    const TIPO_EMISION_DEFAULT = LISTA_TIPO_EMISIONS.length > 0 ? LISTA_TIPO_EMISIONS[0] : null;
    const TIPO_EMISION_SELECTED = LISTA_TIPO_EMISIONS.find((el) => el.id === VENTA.siat_tipo_emision_id) ?? TIPO_EMISION_DEFAULT;
    const LISTA_PUNTO_VENTAS = validarParametroFacturacionSiat(
      RESPONSE.data.data.siat_punto_ventas,
      false,
      rootState.main.extrafacturaElectronica,
    );
    const tipoDocumentoPaciente = TIPO_DOCUMENTOS.find((el) => el.id === VENTA.tipo_doc_identidad_id) ?? null;
    const modalidadSinFactura = { id: MODALIDAD_FACTURACION.SIN_FACTURA, nombre: 'Sin Factura' };
    modalidadesFacturacion.push(modalidadSinFactura);
    const SUCURSAL_SELECTED = sucursales.find((el) => el.id === SUCURSAL_ID);

    const LABORATORY = SERVICIOS_COMPLEMENTARIOS.find((el) => el.servicio_complementario_categoria_id === 9) ?? null;
    if (LABORATORY != null) {
      commit('setHasEnabledLaboratory', (LABORATORY.estado === 1));
    } else {
      commit('setHasEnabledLaboratory', false);
    }
    const TOTAL_SEGURO2 = RESPONSE.data.data.venta.total_seguro2;
    const DESCUENTO_GRAL = RESPONSE.data.data.venta.descuento_general === 1;
    commit('formFactura/setModalidadesFacturas', modalidadesFacturacion, { root: true });
    commit('formFactura/setTipoFactura', TIPO_FACTURA, { root: true });
    commit('formFactura/setTipoContingencias', LISTA_CONTINGENCIAS, { root: true });
    commit('formFactura/setTipoEmisions', LISTA_TIPO_EMISIONS, { root: true });
    commit('formFactura/setTipoEmisionSelected', TIPO_EMISION_SELECTED, { root: true });
    commit('formFactura/setIndentificacionSelected', tipoDocumentoPaciente, { root: true });
    commit('setPuntoVentas', LISTA_PUNTO_VENTAS);
    commit('modalCierreTratamiento/setCierreConfirmado', (tratamiento === 1), { root: true });
    commit('formaPagoModal/setAjustesEnUso', ajustes, { root: true });
    commit('formaPagoModal/setCheckCXP', (ajustes.length > 0), { root: true });
    commit('formFactura/setCheckFacturaTercero', FACTURA_TERCERO, { root: true });
    commit('addServiceModal/setCategoriaServicios', CATEGORIA_SERVICIOS, { root: true });
    commit('addServiceModal/setCategoriaServiciosSelected', CATEGORIA_SERVICIOS[0], { root: true });
    commit('formaPagoModal/setTipoFormaPagos', FORMAS_DE_PAGO, { root: true });
    commit('formaPagoModal/setTesoreriasSelected', TESORERIAS_SELECTED, { root: true });
    commit('formaPagoModal/setComboModalMedioPago', MEDIOS_DE_PAGO, { root: true });
    commit('formaPagoModal/setNroCajas', TESORERIAS_SELECTED.length, { root: true });
    if (FACTURA_TERCERO) {
      commit('formaPagoModal/setFormaPagoFacturaTercero', FORMA_PAGO, { root: true });
      commit('formaPagoFacturaTercero/setCaja', FORMA_PAGO.caja, { root: true });
      commit('formaPagoFacturaTercero/setBanco', FORMA_PAGO.banco, { root: true });
      commit('formaPagoFacturaTercero/setTarjeta', FORMA_PAGO.tarjeta, { root: true });
    } else {
      commit('formaPagoModal/setFormaPago', FORMA_PAGO, { root: true });
      commit('formaPagoModal/setMedioPago', MEDIO_PAGO, { root: true });
    }
    commit('formaPagoModal/setClassButton', 'btn-success', { root: true });
    commit('formFactura/setComboAseguradora', aseguradoras, { root: true });
    commit('formFactura/setCheckSeguroEstado', (SEGURO_MEDICO === 1), { root: true });
    commit('formFactura/cargarSeguroSelectedById', ASEGURADORA_ID, { root: true });
    commit('formFactura/setNit', FACTURA_NIT, { root: true });
    commit('formFactura/setRazonSocial', FACTURA_RAZON_SOCIAL, { root: true });
    commit('formFactura/setDatePicker', FECHA_FACTURA, { root: true });
    dispatch('detalleFactura/cargarSeguroDobleSelectedById', ASEGURADORA_DOBLE_ID, { root: true });
    commit('detalleFactura/setGlosa', glosa, { root: true });
    commit('detalleFactura/setGlosaTipo', GLOSA_TIPO, { root: true });
    commit('formFactura/setMedicos', medicos, { root: true });
    commit('formFactura/setMedicosTratantes', medicosTratantes, { root: true });
    commit('formFactura/setMedicoDerivador', MEDICO_DERIVADOR, { root: true });
    dispatch('tableAndAddService/cargarListaTablaFromEdit', detalles, { root: true });
    commit('formFactura/cargarMedicoSelectedById', MEDICO_SELECTED_ID, { root: true });
    commit('formFactura/setComboMoneda', monedas, { root: true });
    commit('formFactura/cargarMonedaSelectedById', MONEDA_SELECTED_ID, { root: true });
    commit('formFactura/cargarTipoCambio', tc, { root: true });
    commit('addServiceModal/setActividadEconomicaId', ACTIVIDAD_ECONOMICA_ID, { root: true });
    commit('setCorrelativo', numero);
    commit('formFactura/setNroFactura', NUMERO_FACTURA, { root: true });
    commit('formFactura/setPacienteSelected', pacientes, { root: true });
    dispatch('formFactura/getDatosPacienteEditable', PACIENTE_SELECTED_ID, { root: true });
    commit('setShowCortinaInformativa', true);
    dispatch('formFactura/obtenerTratamientosPendientes', null, { root: true });
    commit('detalleFactura/setSubTotal', SUB_TOTAL, { root: true });
    commit('setSucursal', SUCURSAL_SELECTED);
    commit('detalleFactura/setTotalSeguro', parseFloat(TOTAL_SEGURO ?? 0), { root: true });
    commit('detalleFactura/setTotal', parseFloat(total), { root: true });
    commit('formFactura/setCheckValorCero', VALOR_CERO, { root: true });
    commit('formFactura/setNumeroReserva', NUMERO_RESERVA, { root: true });
    commit('modalReserva/setReservaId', RESERVA_ID, { root: true });
    commit('detalleFactura/setTotalSeguroDoble', TOTAL_SEGURO2, { root: true });
    commit('formFactura/setNumeroTratamiento', NUMERO_TRATAMIENTO, { root: true });
    commit('detalleFactura/setDescuento', DESCUENTO, { root: true });
    commit('detalleFactura/setDescuentoGeneral', DESCUENTO_GRAL, { root: true });
  } catch (error) {
    util.showNotify(error.response.data.message, 'error');
  }
}
