import { hasExtrasEmpresa } from '../utilidades';

export default {
  hasPermissionMedicoTercero(state) {
    const MEDICO_TERCERO_FACTURACION = 20;
    return hasExtrasEmpresa(state, MEDICO_TERCERO_FACTURACION);
  },
  hasPermissionPreVenta(state) {
    const PREVENTA = 42;
    return hasExtrasEmpresa(state, PREVENTA);
  },
  hasPermisionTurnoMedico(state) {
    const TURNO_MEDICO = 43;
    return hasExtrasEmpresa(state, TURNO_MEDICO);
  },
  hasPermisionDecuento(state) {
    const DESCUENTO = 9;
    return hasExtrasEmpresa(state, DESCUENTO);
  },
};
